import React from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useContext, useState } from 'react'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import { zodResolver } from '@hookform/resolvers/zod'
import Icono from '../img/logo_mm_forndo_transparente.png'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { classNames } from '../utils/object'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import { recoverUserFormSchema, type RecoverUserFormValues } from '../schemas/loginSchema'
import type { User } from 'adaflex-types'
import type { ACTIONTYPE } from '../Store'
import AdaAPI from 'adaflex-api'

type Context = {
  state: {
    userInfo: User
    adaAPI: AdaAPI
  }
  dispatch: React.Dispatch<ACTIONTYPE>
}

const RecPass = () => {
  const { state }: Context = useContext(Store)
  const [successSent, setSuccessSent] = useState(false)

  const { adaAPI } = state
  const queryClient = useQueryClient()
  const userData = useMutation(
    async (param: string) => {
      return await adaAPI.genCodeRecovery(param)
    },
    {
      onSuccess: (data, variables) => {
        void queryClient.invalidateQueries(['users'])
        if (data?.status === 'Success') {
          toast.success(data?.mensaje as string)
          setSuccessSent(true)
        }
      },
      onError: (error) => {
        toast.error(error instanceof Error)
        setSuccessSent(false)
      },
    },
  )

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<RecoverUserFormValues>({
    resolver: zodResolver(recoverUserFormSchema),
  })

  const submitHandler = async (data: RecoverUserFormValues) => {
    const { username } = data
    await userData.mutateAsync(username)
  }

  function getError(field: keyof RecoverUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  if (userData.data?.status === 'Error') {
    toast.error(userData.data.message)
    toast.clearWaitingQueue()
  }

  return (
    <>
      <Helmet>
        <title>Ingreso</title>
      </Helmet>
      <div className='flex min-h-full flex-col justify-center bg-slate-400 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <Link to='/'>
            <img className='mx-auto h-12 w-auto' src={Icono} alt='Adaflex sistemas' />
          </Link>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-200'>
            Recuperar contraseña
          </h2>
          <p className='mt-2 text-center text-sm text-gray-300'>
            <Link to={`/ingresar`} className='font-medium text-sky-300 hover:text-sky-200'>
              Volver
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(submitHandler)}>
              <div>
                <label
                  htmlFor='username'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Correo electrónico
                </label>
                <div className=' relative mt-2'>
                  <input
                    id='username'
                    {...register('username')}
                    type='text'
                    className={classNames(
                      getError('username')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-600 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('username') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                {getError('username') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('username')}
                  </p>
                )}
              </div>

              <div>
                {!successSent ? (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
                  >
                    Mandar código de recuperación
                  </button>
                ) : (
                  <Link
                    to='/codigo_recuperar'
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
                  >
                    Continuar
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default RecPass
