import z from 'zod'

/** Register Client */
export const updatePasswordFormSchema = z
  .object({
    username: z.string().email({ message: 'Dirección de correo inválida' }),
    password: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    confirmPassword: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    newPass: z
      .string({ required_error: 'No deje vacío este campo' })
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?0&#])[A-Za-z\d@$!%*?&.:]{8,}$/,
        'Formato incorrecto de contraseña',
      )
      .min(1, 'No deje vacío este campo'),
    confirmNewPass: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden',
    path: ['confirmPassword'], // path of error
  })
  .refine((data) => data.newPass === data.confirmNewPass, {
    message: 'Las contraseñas no coinciden',
    path: ['confirmNewPass'], // path of error
  })

export type UpdatePasswordFormValues = z.infer<typeof updatePasswordFormSchema>
