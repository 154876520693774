// App.tsx
import { useEffect, useState } from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'
import { AnimatePresence } from 'framer-motion'
// Páginas
import Home from './pages/Home'
import Contact from './pages/Contact'
import NotFound from './pages/NotFound'
import SignIn from './pages/Ingreso'
import RecPass from './pages/RecPass'
import SignUp from './pages/Registro'
import ShippingAddress from './pages/Envio'
import PassCode from './pages/PassCode'
import Carrito from './pages/CarritoCompras'
import Historial from './pages/Historial'
import FilterProducts from './pages/FilterProducts'
import BuyOrder from './pages/BuyOrder'
import OrderSummary from './pages/OrderSummary'
import BillingPage from './pages/Billing'
import AddressScreen from './pages/ActualizarDomicilio'
import InfoCuenta from './pages/InfoCuenta'
import PasswordScreen from './pages/ActualizarContra'
import PerfilScreen from './pages/ActualizarPerfil'
import Producto from './pages/ProductoIndividual'
import BrandProducts from './pages/BrandProducts'
import GroupProducts from './pages/GroupProducts'

// Componentes
import NavBar from './components/NavBar'
import Footer from './components/Footer'
import SearchProducts from './pages/SearchProducts'

const App = () => {
  const [userInfo, setUserInfo] = useState<boolean>(false)
  useEffect(() => {
    if (secureLocalStorage.getItem('userInfo') && secureLocalStorage.getItem('pf')) {
      setUserInfo(true)
    } else {
      setUserInfo(false)
    }
  }, [])
  const userData = secureLocalStorage.getItem('userInfo')
  const pf = secureLocalStorage.getItem('pf')
  return (
    <BrowserRouter>
      <div className='flex h-screen flex-col'>
        <a
          href='https://wa.me/'
          className='whatsapp_float'
          target='_blank'
          rel='noopener noreferrer'
        >
          <i className='fa fa-whatsapp whatsapp-icon'></i>
        </a>
        <NavBar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/contacto' element={<Contact />} />
          <Route path='/ingresar' element={!userInfo ? <SignIn /> : <Navigate to='/' />} />
          <Route path='/recuperar' element={!userInfo ? <RecPass /> : <Navigate to='/' />} />
          <Route
            path='/codigo_recuperar'
            element={!userInfo ? <PassCode /> : <Navigate to='/' />}
          />
          <Route path='/productos' element={<FilterProducts />} />
          <Route path='/productos/:code' element={<Producto />} />
          <Route path='/marcas/:brandName' element={<BrandProducts />} />
          <Route path='/categoria/:groupName' element={<GroupProducts />} />
          <Route path='/registro' element={!userInfo ? <SignUp /> : <Navigate to='/' />} />
          <Route path='/*' element={<NotFound />} />
          <Route path='/carrito' element={<Carrito />} />
          <Route path='/historial' element={<Historial />} />
          <Route path='/factura/:order' element={<BillingPage />} />
          <Route path='/busqueda/:name' element={<SearchProducts />} />
          <Route
            path='/envio'
            element={userInfo ? <ShippingAddress /> : <Navigate to='/ingresar' />}
          />
          <Route path='/orden' element={userInfo ? <BuyOrder /> : <Navigate to='/ingresar' />} />
          <Route path='/resumen/:number' element={<OrderSummary />} />
          <Route path='*' element={<FilterProducts />} />
          <Route path='/cuenta' element={userInfo ? <InfoCuenta /> : <Navigate to='/' />}>
            <Route index element={userData && pf ? <PerfilScreen /> : <Navigate to='/' />} />
            <Route
              path='perfil'
              element={userData && pf ? <PerfilScreen /> : <Navigate to='/' />}
            />
            <Route path='password' element={userInfo ? <PasswordScreen /> : <Navigate to='/' />} />
            <Route path='domicilio' element={userInfo ? <AddressScreen /> : <Navigate to='/' />} />
          </Route>
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
