import React, { Fragment, useEffect, useReducer, useState } from 'react'
import { ChevronUpIcon } from '@heroicons/react/20/solid'
import { Popover, Transition } from '@headlessui/react'
import { PriceFormat } from '../utils/formatPrice'
import { classNames } from '../utils/object'
import AdaAPI from 'adaflex-api'
import type { Address, Mail, Order, Product, User } from 'adaflex-types'
import secureLocalStorage from 'react-secure-storage'
import { PayPalButtons, PayPalScriptProvider } from '@paypal/react-paypal-js'
import { toast } from 'react-toastify'
import PuffLoader from 'react-spinners/PuffLoader'
import HashLoader from 'react-spinners/HashLoader'

type Props = {
  stateData: {
    cart: {
      shippingAddress: Address
      cartItems: Product[]
      itemsPrice: number
      shippingPrice: number
      taxPrice: number
      totalPrice: number
    }
    pf: any
    userInfo: User
    adaAPI: AdaAPI
  }
  CLIENT_ID: string
  BASE_URL: string
  navigate: any
  colors?: {
    one: string
    two: string
    three: string
    four: string
  }
}

interface State {
  loading: boolean
  // Add more properties if needed
}

interface Action {
  type: string
  // Add more properties if needed
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'CREATE_REQUEST':
      return { ...state, loading: true }
    case 'CREATE_SUCCESS':
      return { ...state, loading: false }
    case 'CREATE_FAIL':
      return { ...state, loading: false }
    default:
      return state
  }
}

export function BuyForm(props: Props) {
  const { stateData, CLIENT_ID, BASE_URL, navigate, colors } = props
  const { cart, pf, userInfo } = stateData
  // @ts-ignore
  const [{ loading }, dispatch] = useReducer(reducer, {
    loading: false
  })

  const round2 = (num: number) => Math.round(num * 100 + Number.EPSILON) / 100 // 123.2345 => 123.23
  cart.itemsPrice = round2(
    cart.cartItems.reduce(
      (a, c) => a + c.quantity * Number(c.price?.replace(/,/g, '.')),
      0
    )
  )

  if (!cart.hasOwnProperty('shippingPrice')) {
    cart.shippingPrice = 0
  }
  if (!cart.hasOwnProperty('taxPrice')) {
    cart.taxPrice = 0
  }
  if (!cart.hasOwnProperty('totalPrice')) {
    cart.totalPrice = 0
  }

  cart.totalPrice = cart.itemsPrice + cart.shippingPrice + cart.taxPrice

  // const navigate = useNavigate()
  const [paymentId, setPaymentId] = useState<string | undefined>()
  const [paypalReady, setPaypalReady] = useState(false)
  const [isProcessing, setIsProcessing] = useState(false)

  useEffect(() => {
    // Simulate an async initialization process (you can replace this with your actual PayPal initialization logic)
    setTimeout(() => {
      setPaypalReady(true) // Set to true when PayPal Buttons are ready
    }, 2000) // Adjust the time as needed
  }, [])

  // creates a paypal order
  const createPayPalPayment = (_data: any, actions: any) => {
    return actions.order
      .create({
        payer: {
          name: {
            given_name: userInfo.nombre,
            surname: `${userInfo.apellidopat} ${userInfo.apellidomat}`
          },
          email_address: userInfo.Usuario,
          phone: {
            phone_type: 'MOBILE',
            phone_number: {
              national_number: userInfo.numero
            }
          },
          address: {
            postal_code: cart.shippingAddress.cpEnvio,
            country_code: 'MX'
          }
        },
        purchase_units: [
          {
            description: 'Productos Adaflex',
            amount: {
              currency_code: 'MXN',
              value: cart.totalPrice
            }
          }
        ],
        application_context: {
          shipping_preference: 'NO_SHIPPING'
        }
      })
      .then((orderID: any) => {
        // setOrderID(orderID)
        return orderID
      })
      .catch((err: string) => {
        console.error(err)
      })
  }

  // check Approval
  const onApprove = (data: any, actions: any) => {
    setIsProcessing(true) // Indica que el pago está siendo procesado
    return actions.order
      .capture()
      .then(function (details: any) {
        // const { payer } = details

        const obj = {
          username: pf.username,
          shipCost: cart.shippingPrice.toString(),
          proveedorPagoId: details.id,
          products: cart.cartItems.map((c) => {
            const obj = {
              code: c.code,
              quantity: c.quantity.toString(),
              price: c.price
            }
            return obj
          })
        }
        placeOrder(obj)
        setPaymentId(data.id)
      })
      .catch((err: any) => {
        setIsProcessing(false)
        console.error(err)
      })
  }

  // capture likely error
  const onError = (err: Record<string, unknown>) => {
    toast.error('No se pudo realizar el pago')
    toast.clearWaitingQueue()
    console.error(err)
  }

  async function placeOrder(body: Order) {
    async function sendMail(body: Mail) {
      return await new AdaAPI(BASE_URL as string)
        .createMail(body)
        .then()
        .catch(() => {
          toast.error('No se pudo realizar el pago')
          toast.clearWaitingQueue()
        })
    }
    try {
      dispatch({ type: 'CREATE_REQUEST' })

      return await new AdaAPI(BASE_URL as string)
        .createOrder(body)
        .then((data) => {
          sendMail({
            username: pf.username,
            numero: data.number,
            asunto: 'Compra realizada con éxito',
            bandera: 'A'
          })
          dispatch({ type: 'CREATE_SUCCESS' })
          secureLocalStorage.removeItem('cartItems') // navigate(`/resumen/${data.number}`)
          navigate(`/resumen/${data.number}`)
        })
        .catch(() => {
          dispatch({ type: 'CREATE_FAIL' })
        })
    } catch (err) {
      dispatch({ type: 'CREATE_FAIL' })
      toast.error('ERROR: 31COT')
    }
  }

  // Initial PayPal config
  const initialOptions = {
    'client-id': CLIENT_ID,
    // 'buyer-country': 'MX', //Revisar si funciona en pruebas
    locale: 'es_MX',
    currency: 'MXN',
    intent: 'capture'
  }

  const backColors = {
    one: '#0a527b',
    two: '#065d8c',
    three: '#0369a1',
    four: '#110356'
  }

  return (
    <div>
      <PayPalScriptProvider options={initialOptions}>
        <div
          aria-hidden='true'
          className={classNames(
            // open ? 'bottom-0' : 'inset-y-0',
            'absolute inset-x-0 z-0 left-1/2 w-full -translate-x-1/2 transform overflow-hidden lg:h-[25vh]'
          )}
        >
          <div className='absolute inset-0 flex'>
            <div
              className='h-full w-1/2'
              style={{ backgroundColor: colors ? colors.one : backColors.one }}
            />
            <div
              className='h-full w-1/2'
              style={{ backgroundColor: colors ? colors.two : backColors.two }}
            />
          </div>
          <div className='relative flex justify-center'>
            <svg
              className='shrink-0'
              width={1750}
              height={308}
              viewBox='0 0 1750 308'
            >
              <path
                d='M284.161 308H1465.84L875.001 182.413 284.161 308z'
                fill={colors ? colors.three : backColors.three}
              />
              <path
                d='M1465.84 308L16.816 0H1750v308h-284.16z'
                fill={colors ? colors.two : backColors.two}
              />
              <path
                d='M1733.19 0L284.161 308H0V0h1733.19z'
                fill={colors ? colors.four : backColors.four}
              />
              <path
                d='M875.001 182.413L1733.19 0H16.816l858.185 182.413z'
                fill={colors ? colors.four : backColors.four}
              />
            </svg>
          </div>
        </div>

        <header className='relative py-10'>
          <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
            <h1 className='text-3xl font-bold tracking-tight text-white'>
              Datos del pedido
            </h1>
          </div>
        </header>
        <main className='relative'>
          <div className='mx-auto max-w-screen-xl px-4 pb-6 sm:px-6 lg:px-8 lg:pb-16'>
            <div className='overflow-hidden rounded-lg bg-white shadow'>
              {/* Background colors split screen for large screens */}
              <div
                className='fixed left-0 top-0 hidden  w-1/2 bg-white lg:block'
                aria-hidden='true'
              />
              <div
                className='fixed right-0 top-0 hidden  w-1/2  lg:block'
                aria-hidden='true'
              />

              <div className='relative mx-auto grid max-w-7xl grid-cols-1 gap-x-16 lg:grid-cols-2 lg:px-8 xl:gap-x-48'>
                <h1 className='sr-only'>Order information</h1>

                <section
                  aria-labelledby='summary-heading'
                  className=' px-4 pb-10 pt-16 sm:px-6 lg:col-start-2 lg:row-start-1 lg:bg-transparent lg:px-0 lg:pb-16'
                >
                  <div className='mx-auto max-w-lg lg:max-w-none'>
                    <h2
                      id='summary-heading'
                      className='text-lg font-medium text-gray-900'
                    >
                      Resumen de pedido
                    </h2>

                    <ul
                      role='list'
                      className='divide-y divide-gray-200 text-sm font-medium text-gray-900'
                    >
                      {cart.cartItems.map((product: any) => (
                        <li
                          key={product.code}
                          className='flex items-start space-x-4 py-6'
                        >
                          <img
                            src={`data:image/jpg;base64,${product.BImagen}`}
                            alt={product.name}
                            className='h-20 w-20 flex-none rounded-md object-cover object-center'
                          />
                          <div className='flex-auto space-y-1'>
                            <h3>{product.name}</h3>
                            <p className='text-gray-500'>
                              {product.quantity} por comprar
                            </p>
                            <p className='text-gray-500'>
                              $
                              {PriceFormat(
                                Number(product.price?.replace(/,/g, '.')),
                                true
                              )}{' '}
                              c/u
                            </p>
                          </div>
                          <p className='flex-none text-base font-medium'>
                            $
                            {PriceFormat(
                              Number(product.price?.replace(/,/g, '.')) *
                                product.quantity,
                              true
                            )}
                          </p>
                        </li>
                      ))}
                    </ul>

                    <dl className='hidden space-y-6 border-t border-gray-200 pt-6 text-sm font-medium text-gray-900 lg:block'>
                      <div className='flex items-center justify-between'>
                        <dt className='text-gray-600'>Subtotal</dt>
                        <dd>${PriceFormat(cart.itemsPrice, true)}</dd>
                      </div>

                      <div className='flex items-center justify-between'>
                        <dt className='text-gray-600'>Envío</dt>
                        <dd>${cart.shippingPrice}</dd>
                      </div>

                      {/* <div className='flex items-center justify-between'>
                      <dt className='text-gray-600'>IVA</dt>
                      <dd>${PriceFormat(cart.taxPrice)}</dd>
                    </div> */}

                      <div className='flex items-center justify-between border-t border-gray-200 pt-6'>
                        <dt className='text-base'>Total</dt>
                        <dd className='text-base'>
                          ${PriceFormat(cart.totalPrice, true)}
                        </dd>
                      </div>
                    </dl>

                    <Popover className='fixed inset-x-0 bottom-0 flex flex-col-reverse text-sm font-medium text-gray-900 lg:hidden'>
                      <div className='relative z-10 border-t border-gray-200 bg-white px-4 sm:px-6'>
                        <div className='mx-auto max-w-lg'>
                          <Popover.Button className='flex w-full items-center py-6 font-medium'>
                            <span className='mr-auto text-base'>Total</span>
                            <span className='mr-2 text-base'>
                              {' '}
                              ${PriceFormat(cart.totalPrice, true)}
                            </span>
                            <ChevronUpIcon
                              className='h-5 w-5 text-gray-500'
                              aria-hidden='true'
                            />
                          </Popover.Button>
                        </div>
                      </div>

                      <Transition.Root as={Fragment}>
                        <div>
                          <Transition.Child
                            as={Fragment}
                            enter='transition-opacity ease-linear duration-300'
                            enterFrom='opacity-0'
                            enterTo='opacity-100'
                            leave='transition-opacity ease-linear duration-300'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                          >
                            <Popover.Overlay className='fixed inset-0 bg-black bg-opacity-25' />
                          </Transition.Child>

                          <Transition.Child
                            as={Fragment}
                            enter='transition ease-in-out duration-300 transform'
                            enterFrom='translate-y-full'
                            enterTo='translate-y-0'
                            leave='transition ease-in-out duration-300 transform'
                            leaveFrom='translate-y-0'
                            leaveTo='translate-y-full'
                          >
                            <Popover.Panel className='relative bg-white px-4 py-6 sm:px-6'>
                              <dl className='mx-auto max-w-lg space-y-6'>
                                <div className='flex items-center justify-between'>
                                  <dt className='text-gray-600'>Subtotal</dt>
                                  <dd>${PriceFormat(cart.itemsPrice, true)}</dd>
                                </div>

                                <div className='flex items-center justify-between'>
                                  <dt className='text-gray-600'>Envío</dt>
                                  <dd>${cart.shippingPrice}</dd>
                                </div>

                                {/* <div className='flex items-center justify-between'>
                                <dt className='text-gray-600'>IVA</dt>
                                <dd>${PriceFormat(cart.taxPrice)}</dd>
                        </div> */}
                              </dl>
                            </Popover.Panel>
                          </Transition.Child>
                        </div>
                      </Transition.Root>
                    </Popover>
                  </div>
                </section>
                <div className='px-4 pb-36 pt-16 sm:px-6 lg:col-start-1 lg:row-start-1 lg:px-0 lg:pb-16'>
                  <div className='mx-auto max-w-lg lg:max-w-none'>
                    <section
                      aria-labelledby='shipping-heading'
                      className='mt-10'
                    >
                      <h2
                        id='shipping-heading'
                        className='text-lg font-medium text-gray-900'
                      >
                        Dirección de envío
                      </h2>

                      <div>
                        <div className='mt-2'>
                          {JSON.stringify(cart.shippingAddress) !== '{}' ? (
                            <address className='not-italic'>
                              <span className='block'>
                                {cart.shippingAddress.nombreEnvio}
                              </span>
                              <span className='block'>
                                {' '}
                                {cart.shippingAddress.numExtEnvio}{' '}
                                {cart.shippingAddress.calleEnvio},{' '}
                                {cart.shippingAddress.municipioEnvio},{' '}
                                {cart.shippingAddress.cpEnvio}
                              </span>
                              <span className='block'>
                                {cart.shippingAddress.estadoEnvio},{' '}
                                {cart.shippingAddress.paisEnvio}
                              </span>
                              <span className='block'>
                                {' '}
                                {cart.shippingAddress.telefonoEnvio}
                              </span>
                            </address>
                          ) : (
                            <div className='block mb-2'>
                              Sin información de envío
                            </div>
                          )}
                        </div>
                      </div>
                      <a
                        href='/cuenta/domicilio'
                        className='w-full rounded-md  border border-transparent bg-adaflex-100 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto'
                      >
                        Cambiar dirección
                      </a>
                    </section>

                    {JSON.stringify(cart.shippingAddress) !== '{}' ? (
                      !paymentId && (
                        <section
                          aria-labelledby='payment-heading'
                          className='mt-10'
                        >
                          <h2
                            id='payment-heading'
                            className='text-lg font-medium text-gray-900'
                          >
                            Medios de Pago
                          </h2>
                          <div className='mt-2'>
                            {paypalReady ? (
                              <div>
                                {isProcessing && (
                                  <div className='flex  mb-4 justify-center items-center'>
                                    <span className='mr-4 font-semibold animate-pulse'>
                                      Procesando el pedido
                                    </span>
                                    <HashLoader color='#F24405' />
                                  </div>
                                )}
                                <PayPalButtons
                                  style={{ layout: 'vertical' }}
                                  createOrder={createPayPalPayment}
                                  onApprove={onApprove}
                                  onError={onError}
                                />
                              </div>
                            ) : (
                              <div className='flex justify-center items-center'>
                                <PuffLoader color='#F24405'>
                                  {' '}
                                  <span className='visually-hidden'>
                                    Cargando...
                                  </span>
                                </PuffLoader>
                              </div>
                            )}
                          </div>
                        </section>
                      )
                    ) : (
                      <div className='mt-10'>
                        Ingrese su dirección para poder continuar
                      </div>
                    )}
                    <div className='mt-10 border-t border-gray-200 pt-6 sm:flex sm:items-center sm:justify-between'>
                      {paymentId ? (
                        <a
                          href='/carrito'
                          className='w-full rounded-md border border-transparent bg-adaflex-100 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto'
                        >
                          Volver{' '}
                        </a>
                      ) : (
                        <a
                          href='/carrito'
                          className='w-full rounded-md border border-transparent bg-adaflex-100 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-50 sm:order-last sm:ml-6 sm:w-auto'
                        >
                          Volver{' '}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </PayPalScriptProvider>
    </div>
  )
}
