import { CheckIcon, ClockIcon, QuestionMarkCircleIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useContext } from 'react'
import { Store } from '../Store'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import '../index.css'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import axiosRetry from 'axios-retry'
import { motion } from 'framer-motion'
import type { Product } from 'adaflex-types'
import type { ACTIONTYPE } from '../Store'
import type { Shipping } from 'adaflex-types'
import AdaAPI from 'adaflex-api'

type InitialState = {
  cart: {
    cartItems: Product[]
    shippingAddress: Shipping
    shippingPrice: number
  }
  adaAPI: AdaAPI
}

const Carrito = () => {
  const navigate = useNavigate()
  const {
    state,
    dispatch: ctxDispatch,
  }: { state: InitialState; dispatch: React.Dispatch<ACTIONTYPE> } = useContext(Store)
  const {
    cart: { cartItems, shippingAddress, shippingPrice },
    adaAPI,
  } = state

  const cartProduct = cartItems.map((item) => item.quantity)
  const updateCartHandler = async (item: Product, quantity: number) => {
    // axiosRetry(axios, {
    //   retries: 3, // number of retries
    //   retryDelay: (retryCount) => {
    //     return retryCount * 500 // time interval between retries
    //   },
    //   retryCondition: (error) => {
    //     // if retry condition is not specified, by default idempotent requests are retried
    //     return error.response?.status !== 200 //otra opcion es === 503
    //   },
    // })
    const { data }: { data: Product } = await axios.get(
      // `https://expressserver-77yd5rczma-uw.a.run.app/api/products/getProduct/${item.code}`,
      `https://srv01.adaflex.mx:4430/datasnap/rest/TSMREST/getproduct/${item.code}`,
      { timeout: 3000 },
    )
    if (Number(data.stock) < quantity) {
      window.alert('Sin existencia')
      return
    } else if (quantity > Number(item.stock) || quantity === 0) {
      return
    } else {
      ctxDispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...item, quantity },
      })
    }
  }
  const removeItemHandler = (item: Product) => {
    ctxDispatch({
      type: 'CART_REMOVE_ITEM',
      payload: item,
    })
  }

  const checkoutHandler = () => {
    if (JSON.stringify(shippingAddress) !== '{}') {
      navigate('/orden')
    } else {
      navigate('/envio')
    }
    //return navigate("/signin?redirect=/envio");
  }
  return (
    <>
      <Helmet>
        <title>Carrito - Adaflex Sistemas</title>
      </Helmet>
      <ToastContainer
        position='top-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />

      <div className='bg-white'>
        <div className='mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8'>
          <motion.main
            initial='hidden'
            animate='visible'
            exit={{ opacity: 0, transition: { duration: 1 } }}
            variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
          >
            <motion.h1
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.25 } },
              }}
              className='text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl'
            >
              Carrito de compras
            </motion.h1>
            <motion.form
              variants={{
                hidden: { opacity: 0, y: 10 },
                visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
              }}
              className='mt-12 lg:grid lg:grid-cols-12 lg:items-start lg:gap-x-12 xl:gap-x-16'
            >
              <section aria-labelledby='cart-heading' className='lg:col-span-7'>
                <h2 id='cart-heading' className='sr-only'>
                  Items in your shopping cart
                </h2>

                <ul role='list' className='divide-y divide-gray-200 border-y border-gray-200'>
                  {cartItems.map((product, productIdx) => (
                    <li key={product.code} className='flex py-6 sm:py-10'>
                      <div className='shrink-0'>
                        {product.BImagen ? (
                          <img
                            src={`data:image/jpg;base64,${product.BImagen}`}
                            alt={product.name}
                            className='h-24 w-24 rounded-md object-cover object-center sm:h-48 sm:w-48'
                          />
                        ) : (
                          <div className='h-24 w-24 border p-4 rounded-md object-cover object-center sm:h-48 sm:w-48'>
                            Sin imagen
                          </div>
                        )}
                      </div>

                      <div className='ml-4 flex flex-1 flex-col justify-between sm:ml-6'>
                        <div className='relative pr-9 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:pr-0'>
                          <div>
                            <div className='flex justify-between'>
                              <h3 className='text-sm'>
                                <Link
                                  to={`/productos/${product.code}`}
                                  className='font-medium text-gray-700 hover:text-gray-800'
                                >
                                  {product.name}
                                </Link>
                              </h3>
                            </div>
                            {/* <div className='mt-1 flex text-sm'>
                            <p className='text-gray-500'>{product.color}</p>
                            {product.size ? (
                              <p className='ml-4 border-l border-gray-200 pl-4 text-gray-500'>
                                {product.size}
                              </p>
                            ) : null}
                          </div> */}
                            <p className='mt-1 text-sm font-medium text-gray-900'>
                              $
                              {Number(product.price?.replace(/,/g, '.'))
                                .toFixed(2)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            </p>
                          </div>

                          <div className='mt-4 sm:mt-0 sm:pr-9'>
                            <label htmlFor={`quantity-${productIdx}`} className='sr-only'>
                              Quantity, {product.name}
                            </label>
                            <input
                              id={`quantity-${productIdx}`}
                              name={`quantity-${productIdx}`}
                              type='number'
                              className='max-w-[50%] rounded-md border border-gray-300 py-1.5 text-center text-base font-medium leading-5 text-gray-700 shadow-sm focus:border-sky-500 focus:outline-none focus:ring-1 focus:ring-sky-500 sm:text-sm'
                              value={product.quantity}
                              onChange={(e) => updateCartHandler(product, Number(e.target.value))}
                              required
                              min='1'
                              max={Number(product.stock)}
                            />
                            <label className='ml-4 text-xs'>
                              (
                              {Number(product.stock) === 1 ? (
                                <span>{product.stock} disponible</span>
                              ) : (
                                <span>{product.stock} disponibles</span>
                              )}
                              )
                            </label>
                            <div className='absolute right-0 top-0'>
                              <button
                                type='button'
                                onClick={() => removeItemHandler(product)}
                                className='-m-2 inline-flex p-2 text-gray-400 hover:text-gray-500'
                              >
                                <span className='sr-only'>Remove</span>
                                <XMarkIcon className='h-5 w-5' aria-hidden='true' />
                              </button>
                            </div>
                          </div>
                        </div>

                        <p className='mt-4 flex space-x-2 text-sm text-gray-700'>
                          {Number(product.stock) > 0 ? (
                            <CheckIcon
                              className='h-5 w-5 shrink-0 text-green-500'
                              aria-hidden='true'
                            />
                          ) : (
                            <ClockIcon
                              className='h-5 w-5 shrink-0 text-gray-300'
                              aria-hidden='true'
                            />
                          )}

                          <span>
                            {Number(product.stock) > 0
                              ? 'Entrega inmediata'
                              : `Consultar tiempo de envío`}
                          </span>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </section>

              {/* Order summary */}
              <section
                aria-labelledby='summary-heading'
                className='mt-16 rounded-lg bg-gray-50 px-4 py-6 sm:p-6 lg:col-span-5 lg:mt-0 lg:p-8'
              >
                <h2 id='summary-heading' className='text-lg font-medium text-gray-900'>
                  Total carrito
                </h2>

                <dl className='mt-6 space-y-4'>
                  <div className='flex items-center justify-between'>
                    <dt className='text-sm text-gray-600'>Subtotal</dt>
                    <dd className='text-sm font-medium text-gray-900'>
                      {' '}
                      $
                      {cartItems
                        .reduce(
                          (a, c) =>
                            Math.round(
                              (a +
                                Number(c.price?.replace(/,/g, '.')) * c.quantity +
                                Number.EPSILON) *
                                100,
                            ) / 100,
                          0,
                        )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </dd>
                  </div>
                  <div className='flex items-center justify-between border-t border-gray-200 pt-4'>
                    <dt className='flex items-center text-sm text-gray-600'>
                      <span>Envío</span>
                      {/* <a href='#' className='ml-2 shrink-0 text-gray-400 hover:text-gray-500'>
                      <span className='sr-only'>Learn more about how shipping is calculated</span>
                      <QuestionMarkCircleIcon className='h-5 w-5' aria-hidden='true' />
                    </a> */}
                    </dt>
                    <dd className='text-sm font-medium text-gray-900'>${shippingPrice}</dd>
                  </div>
                  {/* <div className='flex items-center justify-between border-t border-gray-200 pt-4'>
                  <dt className='flex text-sm text-gray-600'>
                    <span>IVA</span>
                    <a href='#' className='ml-2 shrink-0 text-gray-400 hover:text-gray-500'>
                      <span className='sr-only'>Learn more about how tax is calculated</span>
                      <QuestionMarkCircleIcon className='h-5 w-5' aria-hidden='true' />
                    </a>
                  </dt>
                  <dd className='text-sm font-medium text-gray-900'>$8.32</dd>
                </div> */}
                  <div className='flex items-center justify-between border-t border-gray-200 pt-4'>
                    <dt className='text-base font-medium text-gray-900'>Total de orden</dt>
                    <dd className='text-base font-medium text-gray-900'>
                      {' '}
                      $
                      {cartItems
                        .reduce(
                          (a, c) =>
                            Math.round(
                              (a +
                                Number(c.price?.replace(/,/g, '.')) * c.quantity +
                                Number.EPSILON) *
                                100,
                            ) / 100,
                          0,
                        )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    </dd>
                  </div>
                </dl>

                <div className='mt-6'>
                  {cartItems.length > 0 && (
                    <button
                      type='button'
                      className='mb-2 w-full rounded-md border border-transparent bg-adaflex-100 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 focus:ring-offset-gray-50'
                      onClick={checkoutHandler}
                      disabled={cartItems.length === 0}
                    >
                      Finalizar compra
                    </button>
                  )}
                  <Link to='/'>
                    <button
                      type='button'
                      className='w-full rounded-md border border-transparent bg-adaflex-100 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-700 focus:ring-offset-2 focus:ring-offset-gray-50'
                      onClick={checkoutHandler}
                    >
                      Seguir comprando
                    </button>
                  </Link>
                </div>
              </section>
            </motion.form>
          </motion.main>
        </div>
      </div>
    </>
  )
}

export default Carrito
