import React from 'react'
import Alert from 'react-bootstrap/Alert'

interface Props {
  variant?: string
  children?: React.ReactNode
}

export default function MessageBox(props: Props) {
  return <Alert variant={props.variant || 'info'}>{props.children}</Alert>
}
