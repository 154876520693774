import React, { Fragment } from 'react'
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid'
import { classNames } from '../utils/object'
import type { GetProduct, GetProductBrand } from '../../types/api'

type Props = {
  data?: GetProduct | GetProductBrand
  productoQty: number
  query?: string
}

const Pagination = (props: Props) => {
  const searchParams = new URLSearchParams(location.search)
  const productPage = Number(searchParams.get('pagina')) || 1
  const totalPaginas = Math.ceil((props.data?.totalItems || 0) / (props.productoQty ?? 0))
  const groupSize = 5 // ajusta según sea necesario
  const startPage = Math.max(1, productPage - Math.floor(groupSize / 2))
  const endPage = Math.min(totalPaginas, startPage + groupSize - 1)
  const generarEnlacesPagina = () => {
    const enlaces = []
    for (let i = 1; i <= totalPaginas; i++) {
      enlaces.push(i)
    }
    return enlaces
  }
  return (
    <nav className='flex items-center justify-between border-t border-gray-200 px-4 sm:px-0'>
      <div className='-mt-px flex w-0 flex-1'>
        <a
          href={`${props.query ?? ''}?pagina=${productPage <= 1 ? 1 : productPage - 1}`}
          className='inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
        >
          <ArrowLongLeftIcon className='mr-3 h-5 w-5 text-gray-400' aria-hidden='true' />
          Anterior
        </a>
      </div>
      <div className='hidden md:-mt-px md:flex'>
        {![1, 2, 3].includes(productPage) &&
          (productPage === 4 ? (
            <a
              href={`${props.query ?? ''}?pagina=${1}`}
              className={classNames(
                props.data?.page === '1'
                  ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
              )}
            >
              1
            </a>
          ) : groupSize < generarEnlacesPagina().length ? (
            <Fragment>
              <a
                href={`${props.query ?? ''}?pagina=${1}`}
                className={classNames(
                  props.data?.page === '1'
                    ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                    : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )}
              >
                1
              </a>
              <span className='inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500'>
                ...
              </span>
            </Fragment>
          ) : (
            <Fragment>
              <a
                href={`${props.query ?? ''}?pagina=${1}`}
                className={classNames(
                  props.data?.page === '1'
                    ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                    : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )}
              >
                1
              </a>
              <a
                href={`${props.query ?? ''}?pagina=${2}`}
                className={classNames(
                  props.data?.page === '2'
                    ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                    : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )}
              >
                2
              </a>
            </Fragment>
          ))}
        {generarEnlacesPagina()
          .slice(startPage - 1, endPage)
          .map((p, i) => (
            <a
              key={i}
              href={`${props.query ?? ''}?pagina=${p}`}
              className={classNames(
                props.data?.page === `${p}`
                  ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
              )}
            >
              {p}
            </a>
          ))}
        {groupSize < generarEnlacesPagina().length &&
          (productPage !== generarEnlacesPagina().length &&
          productPage !== generarEnlacesPagina().length - 1 &&
          productPage !== generarEnlacesPagina().length - 2 &&
          productPage == generarEnlacesPagina().length - 3 ? (
            <a
              href={`${props.query ?? ''}?pagina=${generarEnlacesPagina().length}`}
              className={classNames(
                props.data?.page === `${generarEnlacesPagina().length}`
                  ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                  : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
              )}
            >
              {totalPaginas}
            </a>
          ) : (
            <Fragment>
              <span className='inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500'>
                ...
              </span>
              <a
                href={`${props.query ?? ''}?pagina=${generarEnlacesPagina().length}`}
                className={classNames(
                  props.data?.page === `${generarEnlacesPagina().length}`
                    ? 'inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'
                    : 'inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700',
                )}
              >
                {totalPaginas}
              </a>
            </Fragment>
          ))}
      </div>
      <div className='md:hidden -mt-px flex'>
        <p className='inline-flex items-center border-t-2 border-sky-500 px-4 pt-4 text-sm font-medium text-sky-600'>
          {productPage}
        </p>
      </div>
      <div className='-mt-px flex w-0 flex-1 justify-end'>
        <a
          href={`${props.query ?? ''}?pagina=${
            productPage >= generarEnlacesPagina().length
              ? generarEnlacesPagina().length
              : productPage + 1
          }`}
          className='inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'
        >
          Siguiente
          <ArrowLongRightIcon className='ml-3 h-5 w-5 text-gray-400' aria-hidden='true' />
        </a>
      </div>
    </nav>
  )
}
export default Pagination
