import z from 'zod'

/** Register Client */
export const updateUserFormSchema = z
  .object({
    username: z.string().email({ message: 'Dirección de correo inválida' }),
    password: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    confirmPassword: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    nombre: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    apellidoPat: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    apellidoMat: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(1, 'No deje vacío este campo'),
    lada: z.string({ required_error: 'No deje vacío este campo' }),
    telefono: z
      .string({ required_error: 'No deje vacío este campo' })
      .min(10, 'No deje vacío este campo')
      .max(10, 'No deje vacío este campo'),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden',
    path: ['confirmPassword'], // path of error
  })

export type UpdateUserFormValues = z.infer<typeof updateUserFormSchema>
