import axios from "axios";
class AdaAPI {
    // URL base para todas las solicitudes
    baseURL;
    // Constructor de la clase MyAPI
    constructor(baseURL) {
        // Inicializa la URL base con el valor proporcionado al construir una instancia de la clase
        this.baseURL = baseURL;
    }
    async request(method, endpoint, data) {
        // Realiza la solicitud utilizando axios y espera la respuesta
        const response = await axios[method](`${this.baseURL}${endpoint}`, data);
        // Devuelve los datos de la respuesta
        return response.data;
    }
    async sample(method, endpoint, data) {
        // Devuelve los datos de la respuesta
        return `${this.baseURL}${endpoint} - ${method} - ${endpoint} - ${data}`;
    }
    // Begin USERS
    async genCodeRecovery(param) {
        return this.request("get", `/getGenCodeRecovery/${param}`);
    }
    async createUser(body) {
        return this.request("post", "/NewUser", body);
    }
    async signIn(body) {
        return this.request("post", "/fLogin", body);
    }
    async updateUser(body) {
        return this.request("post", "/UpdAccountData", body);
    }
    async updatePassword(body) {
        return this.request("post", "/UpdPassword", body);
    }
    async recoveryPassword(body) {
        // return this.request<AdaResponse>("post", "/RecoveryPassword", body);
        try {
            const response = await this.request("post", "/RecoveryPassword", body);
            return response;
        }
        catch (error) {
            // Handle errors here
            console.error("Error in recoveryPassword:", error);
            throw error; // Optionally re-throw the error
        }
    }
    // End USERS
    // Begin PRODUCTS
    async getProduct(code) {
        return this.request("get", `/getProductImg/${code}`);
    }
    async getProductsImg(productsQty = 9, page = 1) {
        return this.request("get", `/getProductsImg/${productsQty}/${page}`);
    }
    async getProductsAgru(productsQty = 9, page = 1) {
        return this.request("get", `/getProductsAgru/${productsQty}/${page}`);
    }
    async getCatalogos(name, productsQty = 9, page = 1) {
        return this.request("get", `/getCatalogos/${name?.toUpperCase() || ""}/${productsQty}/${page}`);
    }
    async getProductsBrand(brand, productsQty = 9, page = 1) {
        const filterBrand = brand.toUpperCase();
        return this.request("get", `/getCataMarca/${filterBrand}/${productsQty}/${page}`);
    }
    async getProductsBrandAgru(brand, productsQty = 9, page = 1) {
        const filterBrand = brand.toUpperCase();
        return this.request("get", `/getCataMarcaAgru/${filterBrand}/${productsQty}/${page}`);
    }
    async getCataAgrupacion(cat) {
        return this.request("get", `/getCataAgrupacion/${cat}`);
    }
    async getCataLinea(cat, productsQty = 9, page = 1) {
        return this.request("get", `/getCataLinea/${cat}/${productsQty}/${page}`);
    }
    async getCataLineaAgru(cat, productsQty = 9, page = 1) {
        return this.request("get", `/getCataLineaAgru/${cat}/${productsQty}/${page}`);
    }
    // End PRODUCTS
    // Begin ORDER
    async getOrders(user) {
        return this.request("get", `/getOrders/${user}`);
    }
    async getOrderDetail(order) {
        if (order) {
            return this.request("get", `/getOrderDetail/${order}`);
        }
        return undefined;
    }
    async createOrder(body) {
        return this.request("post", "/NewOrder", body);
    }
    async cancelOrder(body) {
        return this.request("post", "/CancelarPedFac", body);
    }
    // End ORDER
    // Begin SHIPPING
    async getShipping(param) {
        return this.request("get", `/GetShipmentData/${param}`);
    }
    async createShipping(body) {
        return this.request("post", "/UpdShipmentData", body);
    }
    // End SHIPPING
    // Begin CONTACT
    async getAdminData() {
        return this.request("get", "/getAdminData");
    }
    async createMail(body) {
        return this.request("post", "/pSendMail", body);
    }
    // End CONTACT
    // Begin BILLING
    async generarCFDI(body) {
        return this.request("post", "/generarCFDI", body);
    }
}
export default AdaAPI;
