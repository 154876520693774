import z from 'zod'

/** Register Client */
export const loginUserFormSchema = z.object({
  username: z.string().email({ message: 'Dirección de correo inválida' }),
  password: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo'),
})

export type LoginUserFormValues = z.infer<typeof loginUserFormSchema>

/** Recover Client */
export const recoverUserFormSchema = z.object({
  username: z.string().email({ message: 'Dirección de correo inválida' }),
})

export type RecoverUserFormValues = z.infer<typeof recoverUserFormSchema>

/** Code Client */
export const codeUserFormSchema = z.object({
  recCode: z.string(),
  newPass: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo'),
})

export type CodeUserFormValues = z.infer<typeof codeUserFormSchema>
