import z from 'zod'

/** Register Client */
export const registerUserFormSchema = z
  .object({
    username: z
      .string({ required_error: 'No deje vacío este campo' })
      .email({ message: 'Dirección de correo inválida' })
      .min(2, 'No deje vacío este campo')
      .max(50, 'No escriba más de 50 letras'),
    nombre: z
      .string({ required_error: 'No deje vacío este campo' })
      .regex(/^[a-zA-Z_ ]+$/, 'No válido')
      .min(3, 'No deje vacío este campo')
      .max(34, 'No se permiten más de 34 letras'),
    apellidoPat: z
      .string({ required_error: 'No deje vacío este campo' })
      .regex(/^[a-zA-Z_ ]+$/, 'No válido')
      .min(2, 'No deje vacío este campo')
      .max(34, 'No se permiten más de 34 letras'),
    apellidoMat: z
      .string({ required_error: 'No deje vacío este campo' })
      .regex(/^[a-zA-Z_ ]+$/, 'No válido')
      .max(34, 'No se permiten más de 34 letras')
      .nullish(),
    telefono: z
      .string()
      .regex(/^\d+$/, 'Escriba únicamente dígitos')
      .min(10, 'Escriba un teléfono válido')
      .max(10, 'Sobre pasó el número máximo de dígitos'),
    password: z
      .string()
      .regex(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?0&#.:ñ])[A-Za-z\d@$!%*?&.:ñ]{8,}$/,
        'Formato incorrecto de contraseña',
      )

      .min(8, 'Escriba por lo menos 8 caracteres'),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: 'Las contraseñas no coinciden',
    path: ['confirmPassword'], // path of error
  })

export type RegisterUserFormValues = z.infer<typeof registerUserFormSchema>
