import { useContext, useEffect } from 'react'
import { Store } from '../Store'
import { useNavigate, useParams } from 'react-router-dom'
import { LoadingBox } from 'lib'
import { Link } from 'react-router-dom'
import MessageBox from '../components/MessageBox'
import { Helmet } from 'react-helmet-async'
import { toast } from 'react-toastify'
import { useQuery } from '@tanstack/react-query'
import type { ACTIONTYPE, State as InitialState } from '../Store'
import { motion } from 'framer-motion'
import AdaAPI from 'adaflex-api'

type Product = {
  Imagen: string
  brand: string
  code: string
  desc: string
  line: string
  longdesc: string
  name: string
  price: string
  stock: string
  type: string
  unit: string
  BImagen: string
  BPDF?: string
}

type GetProductResponse = {
  status: string
  products?: Product[]
}
type State =
  | InitialState
  | {
      userInfo: null
      cart: {
        cartItems: never[]
        shippingAddress: {}
        paymentMethod: string
      }
      adaAPI: AdaAPI
    }

const Producto = () => {
  const navigate = useNavigate()

  const params = useParams()
  const { code } = params
  const { state, dispatch: ctxDispatch }: { state: State; dispatch: React.Dispatch<ACTIONTYPE> } =
    useContext(Store)
  const { cart, adaAPI } = state

  const { data, error, isLoading } = useQuery(
    ['products'],
    () => adaAPI.getProduct(code as string),
    {
      retry: 3,
      retryDelay: 3000,
      networkMode: 'offlineFirst',
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )
  const product = data?.products?.[0]

  useEffect(() => {
    // navigate(0)
    window.scrollTo(0, 0)
  }, [navigate])

  const price = product?.price
  const stringPrice = price?.replace(/,/g, '.')

  const existItem = cart.cartItems?.find((x: Product) => x.code === product?.code)

  const addToCartHandler = (data: GetProductResponse) => {
    const existItem = cart.cartItems?.find((x) => x?.code === product?.code)
    const quantity = existItem ? Number(existItem.quantity) + 1 : 1
    if (Number(data?.products?.[0]?.stock) < quantity) {
      toast.warn('Límite de stock')
      toast.clearWaitingQueue()
      return
    }
    if (product) {
      ctxDispatch({
        type: 'CART_ADD_ITEM',
        payload: { ...product, quantity },
      })
    }
    toast('Producto agregado al carrito', {
      position: 'bottom-left',
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      theme: 'dark',
    })
    toast.clearWaitingQueue()
  }

  if (isLoading) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <LoadingBox color='#075985' value='mrdm' />
      </span>
    )
  }
  if (error instanceof Error) {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>Error de conexión</MessageBox>
        {/* <MessageBox variant='danger'>{error.message}</MessageBox> */}
      </span>
    )
  }
  if (data?.status === 'Error') {
    return (
      <span className='mt-3 flex justify-center container mb-3'>
        <MessageBox variant='danger'>{data.message}</MessageBox>
      </span>
    )
  }

  const visible = { opacity: 1, y: 0, transition: { duration: 0.5 } }

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible,
  }

  return (
    <>
      <Helmet>
        <title>Adaflex Sistemas | {product?.code && `${product?.code}`}</title>
      </Helmet>
      <nav className='flex' aria-label='Breadcrumb'>
        <ol className='my-4 mx-auto inline-flex items-center space-x-1 md:space-x-3'>
          <li className='inline-flex items-center'>
            <Link
              to='/'
              className='inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white'
            >
              <svg
                aria-hidden='true'
                className='mr-2 h-4 w-4'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z'></path>
              </svg>
              Inicio
            </Link>
          </li>
          <li>
            <div className='flex items-center'>
              <svg
                aria-hidden='true'
                className='h-6 w-6 text-gray-400'
                fill='currentColor'
                viewBox='0 0 20 20'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  fillRule='evenodd'
                  d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
                  clipRule='evenodd'
                ></path>
              </svg>
              <a className='ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white md:ml-2'>
                {product?.name}
              </a>
            </div>
          </li>
        </ol>
      </nav>
      <motion.main
        initial='hidden'
        animate='visible'
        exit={{ opacity: 0, transition: { duration: 1 } }}
        variants={{ visible: { transition: { staggerChildren: 0.3 } } }}
      >
        <section className='overflow-hidden bg-white text-gray-700'>
          <div className='container mx-auto px-5 py-24'>
            <div className='mx-auto flex flex-wrap lg:w-4/5'>
              {product &&
                code &&
                (product.BImagen ? (
                  <motion.img
                    className='w-full h-full rounded border p-4 border-gray-200 object-contain  object-center lg:w-1/2'
                    src={`data:image/jpg;base64,${product.BImagen}`}
                    alt={product?.code}
                    variants={{
                      hidden: { opacity: 0, y: -20 },
                      visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
                    }}
                  />
                ) : (
                  <motion.div className=' w-full rounded p-2 border text-lg border-gray-200 object-contain  object-center lg:w-1/2'>
                    Sin imagen
                  </motion.div>
                ))}
              <motion.div
                variants={itemVariants}
                className='mt-6 w-full lg:mt-0 lg:w-1/2 lg:py-6 lg:pl-10'
              >
                <motion.h2
                  variants={itemVariants}
                  className='text-sm tracking-widest text-gray-500'
                >
                  {product?.code}
                </motion.h2>
                <motion.h1
                  variants={itemVariants}
                  className='text-3xl font-bold tracking-tight text-gray-900'
                >
                  {product?.name}
                </motion.h1>
                <motion.div variants={itemVariants} className='mb-4 flex'>
                  <span className='flex items-center'>
                    <span className='ml-3 text-gray-600'>{product?.brand}</span>
                  </span>
                  <span className='ml-3 flex border-l-2 border-gray-200 py-2 pl-3'>
                    <span className='mr-3 font-semibold text-blue-800'>
                      {Number(product?.stock) < 1 ? 'Sin inventario' : product?.stock} disponible
                      {Number(product?.stock) < 1 ? '' : 's'}
                    </span>
                    {/* <a className="text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                    </svg>
                  </a>
                  <a className="ml-2 text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                    </svg>
                  </a>
                  <a className="ml-2 text-gray-500">
                    <svg
                      fill="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="h-5 w-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                    </svg>
                  </a> */}
                  </span>
                </motion.div>

                <motion.div variants={itemVariants} className='flex mb-4 -mt-2'>
                  <span className='text-2xl font-medium text-green-700'>
                    $
                    {Number(stringPrice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    MXN
                  </span>
                </motion.div>

                <p className='leading-relaxed'>
                  Categoría: <span className='font-semibold'>{product?.type}</span>
                </p>
                <div className='space-y-6 text-base text-gray-700'>
                  <p>{product?.desc}</p>
                </div>
                {product?.BPDF && product?.BPDF !== '' && (
                  <embed
                    className='my-5 h-96 w-full rounded-lg border'
                    src={`data:application/pdf;base64,${(product?.BPDF as string) ?? ''}`}
                    type='application/pdf'
                  />
                )}
                {/* <motion.div className='mt-6 mb-5 flex items-center border-b-2 border-gray-200 pb-5'>
                <div className='flex'>
                  <span className='text-2xl font-medium text-green-700'>
                    $
                    {Number(stringPrice)
                      .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}{' '}
                    MXN
                  </span>
                </div>
                {/* <div className='ml-6 flex items-center'>
                  <span className='mr-3 font-semibold text-blue-800'>
                    {Number(product?.stock)} disponible
                    {Number(product?.stock) < 1 ? '' : 's'}
                  </span>
                </div> 
              </div> */}
                <div className='mb-4 pt-4 border-t-2 flex'>
                  {data && (
                    <button
                      onClick={() => addToCartHandler(data)}
                      className='mr-auto flex rounded border-0 bg-adaflex-100 py-2 px-6 text-white hover:bg-blue-800 focus:outline-none'
                    >
                      Agregar a carrito
                    </button>
                  )}
                  <div className='mt-2 ml-4'>
                    <span className='mr-3'>Agregados al carrito: </span>
                    <span className=''>{existItem?.quantity || '0'}</span>
                  </div>
                </div>
                <Link to='/productos'>
                  <button className='mr-2 mb-2 rounded-lg border border-gray-300 bg-white px-5 py-2.5 text-sm font-medium text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-4 focus:ring-gray-200'>
                    Regresar
                  </button>
                </Link>
              </motion.div>
            </div>
          </div>
        </section>
      </motion.main>
    </>
  )
}

export default Producto
