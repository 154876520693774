import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { useContext, useEffect, useState } from 'react'
import { Store } from '../Store'
import { toast } from 'react-toastify'
import { zodResolver } from '@hookform/resolvers/zod'
import Icono from '../img/logo_mm_forndo_transparente.png'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { classNames } from '../utils/object'
import { ExclamationCircleIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useForm } from 'react-hook-form'

import { codeUserFormSchema, type CodeUserFormValues } from '../schemas/loginSchema'
import type { RecoveryCode, User } from 'adaflex-types'
import type { ACTIONTYPE } from '../Store'
import AdaAPI from 'adaflex-api'

type Context = {
  state: {
    userInfo: User
    adaAPI: AdaAPI
  }
  dispatch: React.Dispatch<ACTIONTYPE>
}

const PassCode = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const redirectInUrl = new URLSearchParams(search).get('redirect')
  const redirect = redirectInUrl ? redirectInUrl : '/'
  const [isRevealPwd, setIsRevealPwd] = useState(false)
  const [successSent, setSuccessSent] = useState(false)

  const { state }: Context = useContext(Store)
  const { userInfo } = state

  const queryClient = useQueryClient()
  const userData = useMutation(
    async (body: RecoveryCode) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).recoveryPassword(body)
    },
    {
      onSuccess: (data, variables) => {
        void queryClient.invalidateQueries(['users'])
        if (data?.status === 'Success') {
          toast.success(data?.message)
          setSuccessSent(true)
        }
      },
      onError: (error, data, variables) => {
        toast.error(error instanceof Error)
        setSuccessSent(false)
      },
    },
  )

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm<CodeUserFormValues>({
    resolver: zodResolver(codeUserFormSchema),
  })

  const submitHandler = async (data: CodeUserFormValues) => {
    const { recCode, newPass } = data
    await userData.mutateAsync({
      recCode,
      newPass,
    })
  }

  function getError(field: keyof CodeUserFormValues) {
    if (errors[field]) {
      return errors[field]?.message
    }
    return undefined
  }

  function passwordValidation(value: string) {
    const newPass = watch().newPass
    if (newPass)
      switch (value) {
        case 'length':
          return newPass.length >= 8
        case 'number':
          return /\d/.test(newPass)
        case 'case':
          return /[A-Z]/.test(newPass)
        case 'special':
          return /[@$!%*?&#.:]/.test(newPass)
        default:
          return
      }
  }

  if (userData.data?.status === 'Error') {
    toast.error(userData.data.message)
    toast.clearWaitingQueue()
  }

  useEffect(() => {
    if (userInfo) {
      navigate(redirect)
    }
  }, [navigate, redirect, userInfo])

  return (
    <>
      <Helmet>
        <title>Ingreso</title>
      </Helmet>
      <div className='flex min-h-full flex-col justify-center bg-blue-900 py-12 sm:px-6 lg:px-8'>
        <div className='sm:mx-auto sm:w-full sm:max-w-md'>
          <Link to='/'>
            <img className='mx-auto h-12 w-auto' src={Icono} alt='Adaflex sistemas' />
          </Link>
          <h2 className='mt-6 text-center text-3xl font-bold tracking-tight text-gray-200'>
            Recuperar contraseña
          </h2>
          <p className='mt-2 text-center text-sm text-gray-300'>
            <Link to={`/recuperar`} className='font-medium text-sky-300 hover:text-sky-200'>
              Volver
            </Link>
          </p>
        </div>

        <div className='mt-8 sm:mx-auto sm:w-full sm:max-w-md'>
          <div className='bg-white px-4 py-8 shadow sm:rounded-lg sm:px-10'>
            <form className='space-y-6' onSubmit={handleSubmit(submitHandler)}>
              <div>
                <label
                  htmlFor='recCode'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Código de recuperación
                </label>
                <div className=' relative mt-2'>
                  <input
                    id='recCode'
                    {...register('recCode')}
                    type='text'
                    className={classNames(
                      getError('recCode')
                        ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                        : 'text-gray-900 ring-gray-300 focus:ring-sky-600 placeholder:text-gray-400',
                      'block w-full rounded-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                    )}
                  />
                  {getError('recCode') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                {getError('recCode') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('recCode')}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor='newPass'
                  className='block text-sm font-medium leading-6 text-gray-900'
                >
                  Nueva contraseña
                </label>
                <div className=' flex mt-2'>
                  <div className='relative flex grow items-stretch focus-within:z-10'>
                    <input
                      type={isRevealPwd ? 'text' : 'password'}
                      id='newPass'
                      {...register('newPass')}
                      className={classNames(
                        getError('newPass')
                          ? 'text-red-900 ring-red-300 focus:ring-red-500  placeholder:text-red-300'
                          : 'text-gray-900 ring-gray-300 focus:ring-sky-600 placeholder:text-gray-400',
                        'block w-full rounded-l-md border-0 py-1.5   ring-1 ring-inset  focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6',
                      )}
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => setIsRevealPwd((prevState) => !prevState)}
                    className='relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50'
                  >
                    {isRevealPwd ? (
                      <EyeSlashIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                    ) : (
                      <EyeIcon className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                    )}
                  </button>
                  {getError('newPass') && (
                    <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3'>
                      <ExclamationCircleIcon className='h-5 w-5 text-red-500' aria-hidden='true' />
                    </div>
                  )}
                </div>
                {getError('newPass') && (
                  <p className='mt-2 text-sm text-red-600' id='email-error'>
                    {getError('newPass')}
                  </p>
                )}
                <div>
                  <p className='my-2 text-sm font-medium leading-6 text-gray-900'>
                    Requisitos de contraseña:
                  </p>
                  <ul className='max-w-md space-y-1 text-gray-500 text-sm list-inside dark:text-gray-400'>
                    <li className='flex items-center'>
                      {passwordValidation('length') ? (
                        <svg
                          className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      )}
                      Al menos 8 caracteres
                    </li>
                    <li className='flex items-center'>
                      {passwordValidation('number') ? (
                        <svg
                          className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      )}
                      Al menos un número
                    </li>
                    <li className='flex items-center'>
                      {passwordValidation('case') ? (
                        <svg
                          className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      )}
                      Al menos una letra mayúscula
                    </li>
                    <li className='flex items-center'>
                      {passwordValidation('special') ? (
                        <svg
                          className='w-4 h-4 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      ) : (
                        <svg
                          className='w-4 h-4 mr-1.5 text-gray-400 flex-shrink-0'
                          fill='currentColor'
                          viewBox='0 0 20 20'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            fillRule='evenodd'
                            d='M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z'
                            clipRule='evenodd'
                          ></path>
                        </svg>
                      )}
                      Incluya al menos un caracter especial, e.j., ! @ # ?
                    </li>
                  </ul>
                </div>
              </div>
              <div className='flex items-center justify-between'>
                {/* <div className='flex items-center'>
                  <input
                    id='remember-me'
                    name='remember-me'
                    type='checkbox'
                    className='h-4 w-4 rounded border-gray-300 text-sky-600 focus:ring-sky-600'
                    checked={localStorageSession}
                    onChange={handleCheck}
                  />
                  <label htmlFor='remember-me' className='ml-2 block text-sm text-gray-900'>
                    Recordar cuenta
                  </label>
                </div> */}

                {/* <div className='text-sm'>
                  <a href='#' className='font-medium text-sky-600 hover:text-sky-500'>
                    Voler
                  </a>
                </div> */}
              </div>

              <div>
                {!successSent ? (
                  <button
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
                  >
                    Cambiar contraseña
                  </button>
                ) : (
                  <Link
                    to='/ingresar'
                    type='submit'
                    className='flex w-full justify-center rounded-md bg-sky-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600'
                  >
                    Continuar
                  </Link>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default PassCode
