import { Fragment, useContext, useState } from 'react'
import { Dialog, Popover, Tab, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  ShoppingBagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import Road from '../img/road.jpg'
import Logo from '../img/logo_mm_forndo_transparente.png'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { State, Store } from 'src/Store'
import secureLocalStorage from 'react-secure-storage'
import { Product } from 'adaflex-types'

type Action = {
  type: 'USER_SIGNOUT'
}
type Context = {
  state: State
  dispatch: React.Dispatch<Action>
}

const navigation = {
  // categories: [
  //   {
  //     name: "Women",
  //     featured: [
  //       {
  //         name: "New Arrivals",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-category-01.jpg",
  //         imageAlt:
  //           "Models sitting back to back, wearing Basic Tee in black and bone.",
  //       },
  //       {
  //         name: "Basic Tees",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-category-02.jpg",
  //         imageAlt:
  //           "Close up of Basic Tee fall bundle with off-white, ochre, olive, and black tees.",
  //       },
  //       {
  //         name: "Accessories",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-category-03.jpg",
  //         imageAlt:
  //           "Model wearing minimalist watch with black wristband and white watch face.",
  //       },
  //       {
  //         name: "Carry",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-category-04.jpg",
  //         imageAlt:
  //           "Model opening tan leather long wallet with credit card pockets and cash pouch.",
  //       },
  //     ],
  //   },
  //   {
  //     name: "Men",
  //     featured: [
  //       {
  //         name: "New Arrivals",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-01-men-category-01.jpg",
  //         imageAlt:
  //           "Hats and sweaters on wood shelves next to various colors of t-shirts on hangers.",
  //       },
  //       {
  //         name: "Basic Tees",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-01-men-category-02.jpg",
  //         imageAlt: "Model wearing light heather gray t-shirt.",
  //       },
  //       {
  //         name: "Accessories",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-01-men-category-03.jpg",
  //         imageAlt:
  //           "Grey 6-panel baseball hat with black brim, black mountain graphic on front, and light heather gray body.",
  //       },
  //       {
  //         name: "Carry",
  //         href: "#",
  //         imageSrc:
  //           "https://tailwindui.com/img/ecommerce-images/mega-menu-01-men-category-04.jpg",
  //         imageAlt:
  //           "Model putting folded cash into slim card holder olive leather wallet with hand stitching.",
  //       },
  //     ],
  //   },
  // ],
  pages: [
    { name: 'Inicio', href: '/' },
    { name: 'Productos', href: '/productos' },
    { name: 'Marcas', href: '#' },
    { name: 'Nosotros', href: '#' },
  ],
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const NavBar = () => {
  const { pathname } = useLocation()
  const { state, dispatch: ctxDispatch }: Context = useContext(Store)
  const { cart, userInfo }: State = state
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [mobileSearch, setMobileSearch] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [openSearch, setOpenSearch] = useState(false)
  const navigate = useNavigate()

  const handleSearch = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setOpenSearch(false)
    setMobileSearch(false)
    // Redirigir al usuario a la URL de búsqueda cuando se presione "Enter"
    navigate(`/busqueda/${searchValue}`)
  }

  const handleChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(e.target.value)
  }

  const signoutHandler = () => {
    ctxDispatch({ type: 'USER_SIGNOUT' })
    secureLocalStorage.removeItem('userInfo')
    secureLocalStorage.removeItem('pf')
    secureLocalStorage.removeItem('shippingAddress')
    secureLocalStorage.removeItem('paymentMethod')
    secureLocalStorage.removeItem('cartItems')
    secureLocalStorage.clear()
    window.location.reload()

    // window.location.href = '/'
  }

  if (pathname === '/ingresar') return null
  if (pathname === '/registro') return null
  if (pathname === '/recuperar') return null
  if (pathname === '/codigo_recuperar') return null
  return (
    <>
      {/* Mobile menu */}
      <Transition.Root show={mobileMenuOpen} as={Fragment}>
        <Dialog as='div' className='relative z-40 lg:hidden' onClose={setMobileMenuOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-col overflow-y-auto bg-white pb-12 shadow-xl'>
                <div className='flex px-4 pb-2 pt-5'>
                  <button
                    type='button'
                    className='-m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400'
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    <span className='sr-only'>Close menu</span>
                    <XMarkIcon className='h-6 w-6' aria-hidden='true' />
                  </button>
                </div>

                {/* Links */}
                <Tab.Group as='div' className='mt-2'>
                  <div className='border-b border-gray-200'>
                    <Tab.List className='-mb-px flex space-x-8 px-4'>
                      {/* {navigation.categories.map((category) => (
                        <Tab
                          key={category.name}
                          className={({ selected }) =>
                            classNames(
                              selected
                                ? "border-sky-600 text-sky-600"
                                : "border-transparent text-gray-900",
                              "flex-1 whitespace-nowrap border-b-2 px-1 py-4 text-base font-medium",
                            )
                          }
                        >
                          {category.name}
                        </Tab>
                      ))} */}
                    </Tab.List>
                  </div>
                  <Tab.Panels as={Fragment}>
                    {/* {navigation.categories.map((category) => (
                      <Tab.Panel
                        key={category.name}
                        className="space-y-12 px-4 py-6"
                      >
                        <div className="grid grid-cols-2 gap-x-4 gap-y-10">
                          {category.featured.map((item) => (
                            <div
                              key={item.name}
                              className="group relative"
                            >
                              <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                <img
                                  src={item.imageSrc}
                                  alt={item.imageAlt}
                                  className="object-cover object-center"
                                />
                              </div>
                              <a
                                href={item.href}
                                className="mt-6 block text-sm font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0 z-10"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p
                                aria-hidden="true"
                                className="mt-1 text-sm text-gray-500"
                              >
                                Shop now
                              </p>
                            </div>
                          ))}
                        </div>
                      </Tab.Panel>
                    ))} */}
                  </Tab.Panels>
                </Tab.Group>

                <div className='space-y-6 border-t border-gray-200 px-4 py-6'>
                  {navigation.pages.map((page) => (
                    <div key={page.name} className='flow-root'>
                      <a href={page.href} className='-m-2 block p-2 font-medium text-gray-900'>
                        {page.name}
                      </a>
                    </div>
                  ))}
                </div>

                <div className='space-y-6 border-t border-gray-200 px-4 py-6'>
                  {userInfo ? (
                    <>
                      <div className='flow-root'>
                        <Link to='/cuenta' className='-m-2 block p-2 font-medium text-gray-900'>
                          Perfil
                        </Link>
                      </div>
                      <div className='flow-root'>
                        <Link to='/historial' className='-m-2 block p-2 font-medium text-gray-900'>
                          Pedidos
                        </Link>
                      </div>
                      <div className='flow-root'>
                        <Link
                          onClick={signoutHandler}
                          to='/ingresar'
                          className='-m-2 block p-2 font-medium text-gray-900'
                        >
                          Cerrar sesión
                        </Link>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='flow-root'>
                        <Link to='/registro' className='-m-2 block p-2 font-medium text-gray-900'>
                          Crear cuenta
                        </Link>
                      </div>
                      <div className='flow-root'>
                        <Link to='/ingresar' className='-m-2 block p-2 font-medium text-gray-900'>
                          Ingresar
                        </Link>
                      </div>
                    </>
                  )}
                </div>

                <div className='space-y-6 border-t border-gray-200 px-4 py-6'>
                  {/* Currency selector */}
                  <form>
                    {/* <div className="inline-block">
                      <label
                        htmlFor="mobile-currency"
                        className="sr-only"
                      >
                        Currency
                      </label>
                      <div className="group relative -ml-2 rounded-md border-transparent focus-within:ring-2 focus-within:ring-white">
                        <select
                          id="mobile-currency"
                          name="currency"
                          className="flex items-center rounded-md border-transparent bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-gray-700 focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-800"
                        >
                          {currencies.map((currency) => (
                            <option key={currency}>{currency}</option>
                          ))}
                        </select>
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-500"
                            aria-hidden="true"
                          />
                        </div>
                      </div>
                    </div> */}
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Hero section */}
      <div className='relative bg-gray-900'>
        {/* Decorative image and overlay */}
        {pathname === '/' && (
          <>
            <div aria-hidden='true' className='absolute inset-0 overflow-hidden'>
              <img src={Road} alt='' className='h-full w-full object-cover object-center' />
            </div>
            <div aria-hidden='true' className='absolute inset-0 bg-gray-900 opacity-50' />
          </>
        )}

        {/* Navigation */}
        <header className='relative z-10'>
          <nav aria-label='Top'>
            {/* Top navigation */}
            <div className='bg-gray-900'>
              <div className='mx-auto flex h-10 max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8'>
                {/* Currency selector */}
                <form>
                  <div>
                    {/* <label
                      htmlFor="desktop-currency"
                      className="sr-only"
                    >
                      Currency
                    </label>
                    <div className="group relative -ml-2 rounded-md border-transparent bg-gray-900 focus-within:ring-2 focus-within:ring-white">
                      <select
                        id="desktop-currency"
                        name="currency"
                        className="flex items-center rounded-md border-transparent bg-gray-900 bg-none py-0.5 pl-2 pr-5 text-sm font-medium text-white focus:border-transparent focus:outline-none focus:ring-0 group-hover:text-gray-100"
                      >
                        {currencies.map((currency) => (
                          <option key={currency}>{currency}</option>
                        ))}
                      </select>
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-300"
                          aria-hidden="true"
                        />
                      </div>
                    </div> */}
                  </div>
                </form>

                <p className='flex-1 text-center text-sm font-medium text-white lg:flex-none'>
                  Promoción o Info importante
                </p>

                <div className='flex items-center space-x-6'>
                  {!userInfo && (
                    <>
                      <a
                        href='/registro'
                        className='text-sm font-medium text-white hover:text-gray-100'
                      >
                        Crear cuenta
                      </a>
                      <span className='h-6 w-px bg-prolab-100' aria-hidden='true' />
                      <a
                        href='/ingresar'
                        className='text-sm font-medium text-white hover:text-gray-100'
                      >
                        Ingresar
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Secondary navigation */}
            <div
              className={classNames(
                pathname === '/'
                  ? 'bg-white bg-opacity-10 backdrop-blur-md backdrop-filter'
                  : 'bg-white bg-opacity-40 backdrop-blur-md backdrop-filter',
              )}
            >
              <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
                <div>
                  <div className='flex h-16 items-center justify-between'>
                    {/* Logo (lg+) */}
                    <div className='hidden lg:flex lg:flex-1 lg:items-center'>
                      <a href='/'>
                        <span className='sr-only'>Mercado del Motociclista</span>
                        <img
                          className='h-8 w-auto'
                          src={Logo}
                          alt='Logo Mercado del Motociclista'
                        />
                      </a>
                    </div>

                    <div className='hidden h-full lg:flex'>
                      {/* Flyout menus */}
                      <Popover.Group className='inset-x-0 bottom-0 px-4'>
                        <div className='flex h-full justify-center space-x-8'>
                          {/* {navigation.categories.map((category) => (
                            <Popover
                              key={category.name}
                              className="flex"
                            >
                              {({ open }) => (
                                <>
                                  <div className="relative flex">
                                    <Popover.Button className="relative z-10 flex items-center justify-center text-sm font-medium text-white transition-colors duration-200 ease-out">
                                      {category.name}
                                      <span
                                        className={classNames(
                                          open ? "bg-white" : "",
                                          "absolute inset-x-0 -bottom-px h-0.5 transition duration-200 ease-out",
                                        )}
                                        aria-hidden="true"
                                      />
                                    </Popover.Button>
                                  </div>

                                  <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-200"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="transition ease-in duration-150"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Popover.Panel className="absolute inset-x-0 top-full text-sm text-gray-500">
                                      <div
                                        className="absolute inset-0 top-1/2 bg-white shadow"
                                        aria-hidden="true"
                                      />

                                      <div className="relative bg-white">
                                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                                          <div className="grid grid-cols-4 gap-x-8 gap-y-10 py-16">
                                            {category.featured.map((item) => (
                                              <div
                                                key={item.name}
                                                className="group relative"
                                              >
                                                <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-md bg-gray-100 group-hover:opacity-75">
                                                  <img
                                                    src={item.imageSrc}
                                                    alt={item.imageAlt}
                                                    className="object-cover object-center"
                                                  />
                                                </div>
                                                <a
                                                  href={item.href}
                                                  className="mt-4 block font-medium text-gray-900"
                                                >
                                                  <span
                                                    className="absolute inset-0 z-10"
                                                    aria-hidden="true"
                                                  />
                                                  {item.name}
                                                </a>
                                                <p
                                                  aria-hidden="true"
                                                  className="mt-1"
                                                >
                                                  Shop now
                                                </p>
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </div>
                                    </Popover.Panel>
                                  </Transition>
                                </>
                              )}
                            </Popover>
                          ))} */}

                          {navigation.pages.map((page) => (
                            <a
                              key={page.name}
                              href={page.href}
                              className='flex items-center text-sm font-medium text-white'
                            >
                              {page.name}
                            </a>
                          ))}
                        </div>
                      </Popover.Group>
                    </div>

                    {/* Mobile menu and search (lg-) */}
                    <div className='flex flex-1 items-center lg:hidden'>
                      <button
                        type='button'
                        className='-ml-2 p-2 text-white'
                        onClick={() => setMobileMenuOpen(true)}
                      >
                        <span className='sr-only'>Open menu</span>
                        <Bars3Icon className='h-6 w-6' aria-hidden='true' />
                      </button>

                      {/* Search */}
                      {!openSearch ? (
                        <button
                          onClick={() => {
                            setOpenSearch(true)
                            setMobileSearch(true)
                          }}
                          className='ml-2 p-2 text-white'
                        >
                          <span className='sr-only'>Search</span>
                          <MagnifyingGlassIcon className='h-6 w-6' aria-hidden='true' />
                        </button>
                      ) : (
                        <form
                          className='flex items-center transition-all duration-300'
                          onSubmit={handleSearch}
                        >
                          <label htmlFor='search' className='sr-only'>
                            Buscar
                          </label>
                          <div className='relative'>
                            <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                              <MagnifyingGlassIcon
                                className='h-5 w-5 text-gray-400'
                                aria-hidden='true'
                              />
                            </div>
                            <input
                              id='buscar'
                              name='buscar'
                              className='block border w-full rounded-md bg-sky-100 py-1.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-700 focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6'
                              placeholder='Buscar'
                              type='text'
                              value={searchValue}
                              onChange={handleChange}
                              autoComplete='off'
                            />
                          </div>
                          <button
                            type='submit'
                            onClick={handleSearch}
                            className='p-2.5 ms-2 text-sm font-medium text-white bg-prolab-100 rounded-lg border border-prolab-100 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-500'
                          >
                            <svg
                              className='w-4 h-4'
                              aria-hidden='true'
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 20 20'
                            >
                              <path
                                stroke='currentColor'
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                strokeWidth='2'
                                d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                              />
                            </svg>
                            <span className='sr-only'>Search</span>
                          </button>
                          {mobileSearch && (
                            <button
                              type='button'
                              className='ml-4'
                              onClick={() => {
                                setOpenSearch(false)
                                setMobileSearch(false)
                              }}
                            >
                              <XMarkIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                            </button>
                          )}
                        </form>
                      )}
                    </div>
                    {/* Logo (lg-) */}
                    {!mobileSearch && (
                      <>
                        <a href='/' className='lg:hidden'>
                          <span className='sr-only'>Your Company</span>
                          <img src={Logo} alt='' className='h-8 w-auto' />
                        </a>

                        <div className='flex flex-1 items-center justify-end'>
                          {!openSearch ? (
                            <button
                              onClick={() => setOpenSearch(true)}
                              className='hidden text-sm font-medium text-white lg:block'
                            >
                              Buscar
                            </button>
                          ) : (
                            <form
                              className='flex items-center transition-all duration-300'
                              onSubmit={handleSearch}
                            >
                              {openSearch && (
                                <button
                                  type='button'
                                  className='mr-2'
                                  onClick={() => {
                                    setOpenSearch(false)
                                    setMobileSearch(false)
                                  }}
                                >
                                  <XMarkIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                                </button>
                              )}
                              <label htmlFor='buscar' className='sr-only'>
                                Buscar
                              </label>
                              <div className='relative'>
                                <div className='pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3'>
                                  <MagnifyingGlassIcon
                                    className='h-5 w-5 text-gray-400'
                                    aria-hidden='true'
                                  />
                                </div>
                                <input
                                  id='buscar'
                                  name='buscar'
                                  className='block border w-full rounded-md bg-sky-100 py-1.5 pl-10 pr-3 text-gray-900 placeholder:text-gray-700 focus:bg-white focus:text-gray-900 focus:ring-0 focus:placeholder:text-gray-500 sm:text-sm sm:leading-6'
                                  placeholder='Buscar'
                                  type='text'
                                  value={searchValue}
                                  onChange={handleChange}
                                  autoComplete='off'
                                />
                              </div>
                              <button
                                type='submit'
                                onClick={handleSearch}
                                className='p-2.5 ms-2 text-sm font-medium text-white bg-prolab-100 rounded-lg border border-prolab-100 hover:bg-sky-800 focus:ring-4 focus:outline-none focus:ring-sky-500'
                              >
                                <svg
                                  className='w-4 h-4'
                                  aria-hidden='true'
                                  xmlns='http://www.w3.org/2000/svg'
                                  fill='none'
                                  viewBox='0 0 20 20'
                                >
                                  <path
                                    stroke='currentColor'
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                    strokeWidth='2'
                                    d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
                                  />
                                </svg>
                                <span className='sr-only'>Search</span>
                              </button>
                            </form>
                          )}
                          <div className='flex items-center lg:ml-8'>
                            {/* Help */}
                            <a href='/contacto' className='p-2 text-white lg:hidden'>
                              <span className='sr-only'>Help</span>
                              <QuestionMarkCircleIcon className='h-6 w-6' aria-hidden='true' />
                            </a>
                            <a
                              href='/contacto'
                              className='hidden text-sm font-medium text-white lg:block'
                            >
                              Contacto
                            </a>

                            {/* Cart */}
                            <div className='ml-4 flow-root lg:ml-8'>
                              <a href='/carrito' className='group -m-2 flex items-center p-2'>
                                <ShoppingBagIcon
                                  className='h-6 w-6 flex-shrink-0 text-white'
                                  aria-hidden='true'
                                />
                                <span className='ml-2 text-sm font-medium text-white'>
                                  {' '}
                                  {(cart.cartItems as Product[]).reduce(
                                    (a: number, c: Product): number => {
                                      return Number(a) + c?.quantity
                                    },
                                    0,
                                  )}
                                </span>
                                <span className='sr-only'>artículos en carrito, ver carrito</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        {pathname === '/' && (
          <div className='relative mx-auto flex max-w-3xl flex-col items-center px-6 py-32 text-center sm:py-64 lg:px-0'>
            <h1 className='text-4xl font-bold tracking-tight text-white lg:text-6xl'>
              Nuestros productos
            </h1>
            <p className='mt-4 text-xl text-white'>Proximamente nuestra tienda en línea</p>
            <a
              href='#'
              className='mt-8 inline-block rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100'
            >
              Próximamente
            </a>
          </div>
        )}
      </div>
    </>
  )
}

export default NavBar
