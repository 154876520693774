import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'
import { Tooltip } from 'flowbite-react'
import { useMutation, useQuery } from '@tanstack/react-query'
import { useContext, useState } from 'react'
import { State, Store } from 'src/Store'
import InvoiceModal from 'src/components/InvoiceModal'
import { toast } from 'react-toastify'
import CancelModal from 'src/components/CancelModal'
import AdaAPI from 'adaflex-api'
import { Cancel } from 'adaflex-types'

const InfoFactura = (
  <div className='text-sm'>
    Por el momento no se puede facturar. <br /> La orden debe ser recibida.
  </div>
)

export default function Historial() {
  const [open, setOpen] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)
  const { state }: { state: State } = useContext(Store)
  const { pf, adaAPI } = state
  const [currentInvoice, setCurrentInvoice] = useState<string>()
  const [currentCancelInvoice, setCurrentCancelInvoice] = useState<string>()
  const result = useQuery(['orders'], () => adaAPI.getOrders(pf.username), {
    retry: 3,
    retryDelay: 3000,
    refetchOnWindowFocus: false,
  })

  const cancelOrder = useMutation(
    // adaAPI.cancelOrder
    async (body: Cancel) => {
      return await new AdaAPI(process.env.REACT_APP_BASE_URL as string).cancelOrder(body)
    },
    {
      onSuccess: (data, variables) => {
        // void queryClient.invalidateQueries(['orders'])
        if (data?.status === 'Success') {
          toast.success(data.message)
          toast.clearWaitingQueue()
          setOpenCancel(false)
        } else {
          toast.error(data.message)
          toast.clearWaitingQueue()
          setOpenCancel(false)
        }
      },
      onError: (error) => {
        toast.error(error instanceof Error)
        setOpenCancel(false)
      },
    },
  )

  const onClickCancel = async (order: any, action: any) => {
    if (cancelOrder) {
      await cancelOrder.mutateAsync({
        nodocumento: order,
        accion: action,
        usuario: pf.username,
      })
    }
  }

  /**
   * La función valida que la fecha de compra esté en el mismo mes que la fecha actual para poder facturar
   *
   * @param fechaCompra
   * @returns
   */
  function fechaEnMismoMes(fechaCompra: string): boolean {
    // Obtener la fecha actual
    const fechaActual = new Date()

    // Convertir la fecha de compra al formato de fecha de JavaScript
    const partesFechaCompra = fechaCompra?.split('/')
    const fechaCompraJS = partesFechaCompra
      ? new Date(
          parseInt(partesFechaCompra[2] || '0'), // Año
          parseInt(partesFechaCompra[1] || '0') - 1, // Mes (restamos 1 ya que en JavaScript los meses van de 0 a 11)
          parseInt(partesFechaCompra[0] || '0'), // Día
        )
      : undefined

    // Verificar si ambas fechas están en el mismo mes
    const isSameMonth =
      fechaCompraJS &&
      fechaActual.getMonth() === fechaCompraJS.getMonth() &&
      fechaActual.getFullYear() === fechaCompraJS.getFullYear()

    return !!isSameMonth
  }

  return (
    <>
      <Helmet>
        <title>Pedidos - Adaflex Sistemas</title>
      </Helmet>
      <div className='bg-white'>
        <main className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8 lg:pb-24'>
          <div className='max-w-xl'>
            <h1 className='text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl'>
              Historial de pedidos
            </h1>
            <p className='mt-1 text-sm text-gray-500'>
              Revise el estado de sus compras, facture y descargue recibos.
              {/* Check the status of recent orders, manage returns, and download invoices. */}
            </p>
          </div>
          <section aria-labelledby='recent-heading' className='mt-16'>
            <h2 id='recent-heading' className='sr-only'>
              Recent orders
            </h2>

            <div className='divide-y'>
              {result.data && result.data.orders ? (
                result.data?.orders?.map((order) => (
                  <>
                    <div key={order.orderNumber}>
                      <CancelModal
                        message={order.orderNumber}
                        setOpen={setOpenCancel}
                        open={openCancel && currentCancelInvoice === order.orderNumber}
                        onClickCancel={onClickCancel}
                      />
                      <h3 className='sr-only'>
                        Orden realizada el <time>{order.orderDate}</time>
                      </h3>
                      <div>
                        <div>
                          <h3 className='sr-only'>
                            Orden realizada el <time>12/12/23</time>
                          </h3>
                          <div className='rounded-lg bg-gray-50 px-4 py-6 sm:flex sm:items-center sm:justify-between sm:space-x-6 sm:px-6 lg:space-x-8'>
                            <dl className='flex-auto space-y-6 divide-y divide-gray-200 text-sm text-gray-600 sm:grid sm:grid-cols-3 sm:gap-x-6 sm:space-y-0 sm:divide-y-0 lg:w-1/2 lg:flex-none lg:gap-x-8'>
                              <div className='flex justify-between sm:block'>
                                <dt className='font-medium text-gray-900'>Fecha de orden</dt>
                                <dd className='sm:mt-1'>
                                  <time>{order.orderDate}</time>
                                </dd>
                              </div>
                              <div className='flex justify-between pt-6 sm:block sm:pt-0'>
                                <dt className='font-medium text-gray-900'>Estatus del pedido</dt>
                                <dd className='sm:mt-1'>{order.orderStatus}</dd>
                              </div>
                              <div className='flex justify-between pt-6 font-medium text-gray-900 sm:block sm:pt-0'>
                                <dt>Guía</dt>
                                <dd className='sm:mt-1'>{order.trackingGuide}</dd>
                              </div>
                            </dl>
                            <dd className='inline-flex'>
                              {order.orderBilling && (
                                <Link
                                  to={`/factura/${order.orderNumber}`}
                                  className='mt-6 mr-6 flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                                >
                                  Solicitar factura
                                  <span className='sr-only'>for order </span>
                                </Link>
                              )}
                              {
                                //Solo permite cancelar la factura en caso de que el pedido esté facturado
                                //Falta definir el plazo
                                order.orderStatus === 'F' && (
                                  <button
                                    onClick={async () => {
                                      await onClickCancel(order.orderNumber, 'F')
                                    }}
                                    className='mt-6 mr-6  border-red-600 bg-red-300 flex w-full items-center justify-center rounded-md border px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                                  >
                                    Cancelar factura
                                    <span className='sr-only'>for order </span>
                                  </button>
                                )
                              }

                              <button
                                onClick={() => {
                                  setOpen(true)

                                  setCurrentInvoice(order.orderNumber)
                                }}
                                className='mt-6 mr-6 flex w-full items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                              >
                                Ver recibo
                                <span className='sr-only'>for order </span>
                              </button>
                              {order.orderBilling &&
                                (order.trackingGuide === '' ||
                                  order.trackingGuide === 'Sin asignar') && (
                                  <button
                                    onClick={() => {
                                      setOpenCancel(true)
                                      setCurrentCancelInvoice(order.orderNumber)
                                    }}
                                    className='mt-6 flex w-full items-center justify-center rounded-md border border-red-600 bg-red-300 px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2 sm:mt-0 sm:w-auto'
                                  >
                                    Cancelar pedido
                                    <span className='sr-only'>for order </span>
                                  </button>
                                )}
                            </dd>
                          </div>
                        </div>
                        {/* <div className='px-8'>
                        <table className='mt-4 w-full text-gray-500 sm:mt-6'>
                          <caption className='sr-only'>Products</caption>
                          <thead className='sr-only text-left text-sm text-gray-500 sm:not-sr-only'>
                            <tr>
                              <th scope='col' className='py-3 pr-8 font-normal sm:w-2/5 lg:w-1/3'>
                                Producto
                              </th>
                              <th
                                scope='col'
                                className='hidden w-1/5 py-3 pr-8 font-normal sm:table-cell'
                              >
                                Precio
                              </th>
                              <th
                                scope='col'
                                className='hidden py-3 pr-8 font-normal sm:table-cell'
                              >
                                Estado de compra
                              </th>
                              <th scope='col' className='w-0 py-3 text-right font-normal'>
                                Info
                              </th>
                            </tr>
                          </thead>
                          <tbody className='divide-y divide-gray-200 border-b border-gray-200 text-sm sm:border-t'> */}
                        {/* {data?.products?.map((product: any) => (
          <ProductOrder product={product} key={product.code} />
        ))} */}
                        {/* </tbody>
                        </table> */}
                        {/* <OrderDetails
                        user={pf.username}
                        order={order.orderNumber}
                        readyToFetch={result.isSuccess}
                      /> */}
                        {/* </div> */}
                      </div>
                      {currentInvoice === order.orderNumber && (
                        <InvoiceModal
                          open={open}
                          status={order.orderStatus}
                          user={pf.username}
                          order={order.orderNumber}
                          setOpen={setOpen}
                        />
                      )}
                    </div>
                  </>
                ))
              ) : (
                <div>
                  <p className='text-2xl font-bold tracking-tight text-gray-900 sm:text-xl'>
                    {result?.data?.message}
                  </p>
                </div>
              )}
            </div>
          </section>
        </main>
      </div>
    </>
  )
}
