export function PriceFormat(value: number | undefined, IVA = false) {
  if (value) {
    if (!IVA) {
      const format = (value * 1.16)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return format
    } else {
      const format = value
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      return format
    }
  } else {
    return
  }
}
