import z from 'zod'

/** Save Shipping Address */
export const shippingnUserFormSchema = z.object({
  nombreEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(10, 'Escriba su nombre completo'),
  telefonoEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(10, 'Escriba un teléfono válido')
    .max(10, 'Escriba un teléfono válido'),
  cpEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(5, 'Escriba un código postal válido')
    .max(8, 'Escriba un código postal válido'),
  calleEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(10, 'Escriba una dirección de envío válida')
    .max(50, 'Escriba una dirección de envío válida'),
  numExtEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo')
    .max(10, 'Escriba un número válido'),
  numIntEnvio: z.string(),
  coloniaEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(4, 'Escriba una colonia válida')
    .max(50, 'Escriba una colonia válida'),
  municipioEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(4, 'Escriba un municipio válido')
    .max(50, 'Escriba un municipio válido'),
  ciudadEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo'),
  estadoEnvio: z
    .string({ required_error: 'No deje vacío este campo' })
    .min(1, 'No deje vacío este campo'),
  paisEnvio: z.string({ required_error: 'No deje vacío este campo' }),
})

export type ShippingUserFormValues = z.infer<typeof shippingnUserFormSchema>
