import { useContext, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import AdaAPI from 'adaflex-api'
import { Store } from 'src/Store'
import { Link } from 'react-router-dom'
import { FunnelIcon, PlusIcon } from '@heroicons/react/24/outline'
type Context = {
  state: {
    adaAPI: AdaAPI
  }
}

export default function Home() {
  const { state }: Context = useContext(Store)
  const { adaAPI } = state
  const { data, error, isLoading } = useQuery(['products'], () => adaAPI.getProductsAgru(1, 1), {
    retry: 3,
    retryDelay: 3000,
  })
  const categories = [] as {
    name: string
    href: string
    imageSrc: string
  }[]
  const collections = [] as {
    name: string
    href: string
    imageSrc: string
    imageAlt: string
    description: string
  }[]

  const seenNames = new Set()
  data?.products?.forEach((product) => {
    if (!seenNames.has(product.line)) {
      const obj = {
        name: product.line,
        href: '/productos',
        imageSrc: `data:image/jpg;base64,${product.BImagen}`,
      }
      categories.push(obj)
      seenNames.add(product.line)
    }
  })
  const allowedBrands = ['SHOEI', 'OLIMPIA', 'KENDA']
  data?.products?.forEach((product) => {
    if (allowedBrands.includes(product.brand)) {
      const obj = {
        name: product.brand,
        href: '/productos',
        imageSrc: `data:image/jpg;base64,${product.BImagen}`,
        imageAlt: product.brand,

        description:
          'Explora nuestra selección de accesorios para motos. Calidad, durabilidad y estilo en cada producto. ¡Equípate para tu próxima aventura!',
      }
      collections.push(obj)
    }
  })
  const group = data?.lines?.map((g) => {
    const obj = {
      nombre: g.linea,
      cantidad: g.cantidad,
      href: `/categoria/${g.linea}`,
      imageSrc: ``,
      // imageSrc: `https://motodifer.adaflex.mx/imagenes/${g.tipo}.jpg`,
    }
    return obj
  })

  return (
    <>
      <main className='mb-16'>
        {/* Category section */}
        {/* <section
          aria-labelledby='category-heading'
          className='pt-24 sm:pt-32 xl:mx-auto xl:max-w-7xl xl:px-8'
        >
          <div className='px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0'>
            <h2 id='category-heading' className='text-2xl font-bold tracking-tight text-gray-900'>
              Compre por categoría
            </h2>
            <a
              href='/productos'
              className='hidden text-sm font-semibold text-sky-600 hover:text-sky-500 sm:block'
            >
              Ver productos
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </div>

          <div className='mt-4 flow-root'>
            <div className='-my-2'>
              <div className='relative box-content h-80 overflow-x-auto py-2 xl:overflow-visible'>
                <div className='absolute flex space-x-8 px-4 sm:px-6 lg:px-8 xl:relative xl:grid xl:grid-cols-5 xl:gap-x-8 xl:space-x-0 xl:px-0'>
                  {categories.map((category) => (
                    <a
                      key={category.name}
                      href={category.href}
                      className='relative flex h-80 w-56 flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto'
                    >
                      <span aria-hidden='true' className='absolute inset-0'>
                        <img
                          src={category.imageSrc}
                          alt=''
                          className='h-full w-full object-cover object-center'
                        />
                      </span>
                      <span
                        aria-hidden='true'
                        className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                      />
                      <span className='relative mt-auto text-center text-xl font-bold text-white'>
                        {category.name}
                      </span>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className='mt-6 px-4 sm:hidden'>
            <a href='#' className='block text-sm font-semibold text-sky-600 hover:text-sky-500'>
              Ver productos
              <span aria-hidden='true'> &rarr;</span>
            </a>
          </div>
        </section> */}

        <div className='mx-auto max-w-2xl px-4 lg:max-w-7xl lg:px-8'>
          <div className='pb-24 pt-12'>
            <section aria-labelledby='product-heading' className='mt-6 lg:mt-0'>
              <h2 id='product-heading' className='sr-only'>
                Productos
              </h2>
              <div className='bg-white'>
                <div className='py-16 sm:py-24 xl:mx-auto xl:max-w-7xl xl:px-8'>
                  <div className='px-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8 xl:px-0'>
                    <h2 className='text-2xl font-bold tracking-tight text-gray-900'>
                      Comprar por categoría
                    </h2>
                    {/* <a
                        href='#'
                        className='hidden text-sm font-semibold text-sky-600 hover:text-sky-500 sm:block'
                      >
                        Ver todos los productos
                        <span aria-hidden='true'> &rarr;</span>
                      </a> */}
                  </div>

                  <div className='mt-4 flow-root'>
                    <div className='-my-2'>
                      <div className='relative box-content overflow-x-auto py-2 xl:overflow-visible'>
                        <div className='grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8 xl:grid-cols-4'>
                          {group?.map((category, index) => {
                            return (
                              <div
                                key={index}
                                className='group relative flex flex-col overflow-hidden rounded-lg border border-gray-200 bg-white transition ease-in-out hover:-translate-y-1 hover:scale-105 duration-150'
                              >
                                <Link
                                  to={category.href}
                                  key={category.nombre}
                                  className='relative flex h-80  flex-col overflow-hidden rounded-lg p-6 hover:opacity-75 xl:w-auto'
                                >
                                  <span aria-hidden='true' className='absolute inset-0'>
                                    <img
                                      src={category.imageSrc}
                                      alt=''
                                      className='h-full w-full object-cover object-center'
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement // Cast the target to HTMLImageElement
                                        target.style.display = 'none' // Hide the image on error
                                      }}
                                    />
                                  </span>
                                  <span
                                    aria-hidden='true'
                                    className='absolute inset-x-0 bottom-0 h-2/3 bg-gradient-to-t from-gray-800 opacity-50'
                                  />
                                  <span className='relative mt-auto text-center text-xl font-bold text-white'>
                                    {category.nombre
                                      ?.toLowerCase()
                                      .replace(/^\w/, (c: string) => c.toUpperCase())}
                                  </span>
                                </Link>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='mt-6 px-4 sm:hidden'>
                    {/* <a
                        href='#'
                        className='block text-sm font-semibold text-sky-600 hover:text-sky-500'
                      >
                        Ver todos los productos
                        <span aria-hidden='true'> &rarr;</span>
                      </a> */}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>

        {/* Featured section */}
        {/* <section
          aria-labelledby='social-impact-heading'
          className='mx-auto max-w-7xl px-4 pt-24 sm:px-6 sm:pt-32 lg:px-8'
        >
          <div className='relative overflow-hidden rounded-lg'>
            <div className='absolute inset-0'>
              <img
                src='https://tailwindui.com/img/ecommerce-images/home-page-01-feature-section-01.jpg'
                alt=''
                className='h-full w-full object-cover object-center'
              />
            </div>
            <div className='relative bg-gray-900 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16'>
              <div className='relative mx-auto flex max-w-3xl flex-col items-center text-center'>
                <h2
                  id='social-impact-heading'
                  className='text-3xl font-bold tracking-tight text-white sm:text-4xl'
                >
                  <span className='block sm:inline'>Explora nuestro</span>
                  <span className='block sm:inline'>catálogo de productos</span>
                </h2>
                <p className='mt-3 text-xl text-white'>
                  Encuentra la motocicleta que se adapte a tu estilo y necesidades. Desde modelos
                  clásicos hasta las últimas novedades, tenemos lo que buscas para tu próxima
                  aventura sobre ruedas.
                </p>
                <a
                  href='#'
                  className='mt-8 block w-full rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto'
                >
                  Ver Catálogo
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* Collection section */}
        {/* <section
          aria-labelledby='collection-heading'
          className='mx-auto max-w-xl px-4 pt-24 sm:px-6 sm:pt-32 lg:max-w-7xl lg:px-8'
        >
          <h2 id='collection-heading' className='text-2xl font-bold tracking-tight text-gray-900'>
            Comprar por Marcas
          </h2>
          <p className='mt-4 text-base text-gray-500'>
            Nuestras alianzas estratégicas con marcas líderes nos permiten ofrecerte colecciones
            únicas, inspiradas en la esencia genuina del mundo motociclista y sus accesorios.
          </p>

          <div className='mt-10 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:space-y-0'>
            {collections.map((collection) => (
              <a key={collection.name} href={collection.href} className='group block'>
                <div
                  aria-hidden='true'
                  className='aspect-h-2 aspect-w-3 overflow-hidden rounded-lg lg:aspect-h-6 lg:aspect-w-5 group-hover:opacity-75'
                >
                  <img
                    src={collection.imageSrc}
                    alt={collection.imageAlt}
                    className='h-full w-full object-cover object-center'
                  />
                </div>
                <h3 className='mt-4 text-base font-semibold text-gray-900'>{collection.name}</h3>
                <p className='mt-2 text-sm text-gray-500'>{collection.description}</p>
              </a>
            ))}
          </div>
        </section> */}

        {/* Featured section */}
        {/* <section
          aria-labelledby='comfort-heading'
          className='mx-auto max-w-7xl px-4 py-24 sm:px-6 sm:py-32 lg:px-8'
        >
          <div className='relative overflow-hidden rounded-lg'>
            <div className='absolute inset-0'>
              <img
                src='https://tailwindui.com/img/ecommerce-images/home-page-01-feature-section-02.jpg'
                alt=''
                className='h-full w-full object-cover object-center'
              />
            </div>
            <div className='relative bg-gray-900 bg-opacity-75 px-6 py-32 sm:px-12 sm:py-40 lg:px-16'>
              <div className='relative mx-auto flex max-w-3xl flex-col items-center text-center'>
                <h2
                  id='comfort-heading'
                  className='text-3xl font-bold tracking-tight text-white sm:text-4xl'
                >
                  Productividad sobre ruedas
                </h2>
                <p className='mt-3 text-xl text-white'>
                  Tareas emocionantes, aventuras ilimitadas, un único destino: la carretera. No es
                  solo un medio de transporte, es un estilo de vida. Sin límites, sin fronteras,
                  solo la libertad en dos ruedas.
                </p>
                <a
                  href='#'
                  className='mt-8 block w-full rounded-md border border-transparent bg-white px-8 py-3 text-base font-medium text-gray-900 hover:bg-gray-100 sm:w-auto'
                >
                  Explorar Productos
                </a>
              </div>
            </div>
          </div>
        </section> */}
      </main>
    </>
  )
}
