export function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}
export function reemplazarSlashPorGuionBajo(cadena: string) {
  return cadena.replace(/\//g, "_");
}

export function reemplazarGuionBajoPorSlash(cadena: string) {
  return cadena.replace(/_/g, "/");
}

export function primeraMayuscula(palabra: string) {
  // Convertir la palabra a minúsculas
  let palabraMinusculas = palabra.toLowerCase();

  // Convertir la primera letra a mayúscula
  let primeraLetraMayuscula = palabraMinusculas.charAt(0).toUpperCase();

  // Concatenar la primera letra mayúscula con el resto de la palabra en minúsculas
  return primeraLetraMayuscula + palabraMinusculas.slice(1);
}

export function reemplazarAsteriscos(texto: string) {
  return texto.replace(/\*/g, "");
}
